import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, useTheme, TextField, IconButton } from '@mui/material';
import { Delete, Add, Remove } from '@mui/icons-material';
import SetHeaderData from '../components/SetHeaderData';
import { useNavigate } from 'react-router-dom';

const Shop = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);
  const [articles, setArticles] = useState(null);
  const [shoppingCart, setShoppingCart] = useState([]);
  const [catalogueQuantities, setCatalogueQuantities] = useState({});
  const navigate = useNavigate();
  const [view, setView] = useState('catalogue');
  const [basketButtonText, setBasketButtonText] = useState(null);
  const [scNumberOfItems, setScNumberOfItems] = useState(0);

  useEffect(() => {
    const loadContent = async () => {
      const response_shop = await import(`../data/shop/${language}.json`);
      setContent(response_shop);
      setBasketButtonText(response_shop.shopping_cart_button + " " + scNumberOfItems);
      const initialQuantities = {};
      const response_articles = await import(`../data/articles/${language}.json`);
      setArticles(response_articles);
      response_articles.catalogue.forEach((article) => {
        initialQuantities[article.id] = 1;
      });
      setCatalogueQuantities(initialQuantities);
    };
    loadContent();
  }, [language]);

  const addArticle = (article, quantity = 1) => {
    setShoppingCart((prevCart) => {
      const existingItem = prevCart.find((item) => item.id === article.id);
      if (existingItem) {
        return prevCart.map((item) =>
          item.id === article.id ? { ...item, quantity: item.quantity + quantity } : item
        );
      }
      return [...prevCart, { ...article, quantity }];
    });
  };

  useEffect(() => {
    setScNumberOfItems(shoppingCart.reduce((total, article) => total + article.quantity, 0))
  },[shoppingCart])

  useEffect(() => {
    if (content) {
      setBasketButtonText(`${content.shopping_cart_button} ${scNumberOfItems}`);
    }
  }, [scNumberOfItems, content]);

  const removeArticle = (id) => {
    setShoppingCart((prevCart) => prevCart.filter((item) => item.id !== id));
  };

  const updateQuantity = (id, change) => {
    setShoppingCart((prevCart) =>
      prevCart.map((item) =>
        item.id === id
          ? { ...item, quantity: Math.max(1, item.quantity + change) }
          : item
      )
    );
  };

  const updateCatalogueQuantity = (id, value) => {
    setCatalogueQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: Math.max(1, parseInt(value, 10) || 1),
    }));
  };

  const calculateTotal = () =>
    shoppingCart.reduce((total, article) => total + article.price * article.quantity, 0).toFixed(2);

  if (!content || !basketButtonText || !articles) return <Typography>Loading...</Typography>;

  return (
    <Container>
      <SetHeaderData content={content} />

      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ my: 2 }}>
          {content.title}
        </Typography>
        <Box sx={{ my: 4, display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (view === 'catalogue') {
                setView('shoppingCart');
                setBasketButtonText(content.continue_shopping);
              } else {
                setView('catalogue');
                setBasketButtonText(content.shopping_cart_button + " " + scNumberOfItems);
              }
            }}
          >
            {basketButtonText}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/checkout', { state: { shoppingCart } })}
          >
            {content.checkout}
          </Button>
        </Box>

        {view === 'catalogue' &&
          articles.catalogue.map((article) => (
            <Box
              key={article.id}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'flex-start',
                gap: 2,
                p: 3,
                mb: 4,
                border: '1px solid',
                borderColor: theme.palette.divider,
                borderRadius: 4,
                backgroundColor: theme.palette.background.paper,
                boxShadow: 3,
              }}
            >
              {/* Product Image */}
              <Box sx={{ flexShrink: 0, width: 150, height: 150 }}>
                <img
                  src={article.image}
                  alt={article.title}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: 4,
                  }}
                />
              </Box>

              {/* Product Details */}
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h5" sx={{ mb: 1, fontWeight: 'bold' }}>
                  {article.title}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {article.description}
                </Typography>
                {article.link && (
                  <Typography
                    variant="body2"
                    component="a"
                    href={article.link}
                    sx={{ color: theme.palette.divider, textDecoration: 'none', mb: 2, display: 'block' }}
                  >
                    {article.link_description}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                  onClick={() => addArticle(article, catalogueQuantities[article.id])}
                >
                  {content.buy_now} {article.price} CHF
                </Button>
              </Box>

              {/* Action Section */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 1,
                  mt: { xs: 2, sm: 0 },
                }}
              >
                <TextField
                  type="number"
                  inputProps={{ min: 1 }}
                  value={catalogueQuantities[article.id] || 1}
                  onChange={(e) => updateCatalogueQuantity(article.id, e.target.value)}
                  sx={{ width: 100 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => addArticle(article, catalogueQuantities[article.id])}
                  sx={{ mt: 1 }}
                >
                  {content.add_to_cart}
                </Button>
              </Box>
            </Box>
          ))}


        {view === 'shoppingCart' && (
          <Box
            sx={{
              my: 4,
              p: 2,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography variant="h5" sx={{ my: 2 }}>
              {content.shopping_cart}
            </Typography>
            {shoppingCart.map((item) => (
              <Box key={item.id} sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
                <Typography>
                {item.quantity} x {item.title} - {item.price} CHF
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton onClick={() => updateQuantity(item.id, -1)}>
                    <Remove />
                  </IconButton>
                  <Typography>{item.quantity}</Typography>
                  <IconButton onClick={() => updateQuantity(item.id, 1)}>
                    <Add />
                  </IconButton>
                  <IconButton onClick={() => removeArticle(item.id)}>
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            ))}
            <Typography variant="h6" sx={{ my: 2 }}>
              {content.total}: {calculateTotal()} CHF
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Shop;
